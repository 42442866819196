import React  from "react";
import axios from "axios";
import feapiurl from "../feapiurl";
import MasterDivs from "../content/MasterDivs";
import ImageSlider from "../content-parts/ImageSlider";
import ImageGallery from "../content-parts/ImageGallery";
import NewsSlide from "../content-parts/NewsSlide";
import imageurl from "../imageurl";
function LoadImageContent({ pageid, mediaType, marginBottom, className  }) {

    const [loadedImages, setLoadedImages] = React.useState([]);

    const fetchurl =feapiurl+ "getpagimages.php";
   React.useEffect(() => {
        const getData = async () => {
            try {
                const response = await axios.post(fetchurl);
                const result = response.data;
                const filteredImages = result.filter((img) => img.page_id === pageid);

                setLoadedImages(filteredImages);


            }

             catch (error) {
                console.error('Fehler', error);
            }
        };

        getData();
    }, [pageid]);

    return (
        <MasterDivs hasFlexRow={false} marginBottom={marginBottom}>
            {
                loadedImages.length > 1
                ?(

                            mediaType === "slider"
                    ?(

                             //   <ImageSlider loadedImages={loadedImages} />
                            <ImageSlider loadedImages={loadedImages}/>
                            )

                     :(<ImageGallery loadedImages={loadedImages} className={className}/> )



                    )
                    :(
                        "<p>Jier</p>"
                    )
            }

        </MasterDivs>
                )


}
export default LoadImageContent;