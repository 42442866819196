import React from "react";
import axios from "axios";
import feapiurl from "../feapiurl";
import MasterDivs from "../content/MasterDivs";
import {Link} from "react-router-dom";
import mdstyles from "../styles/css/content/masterdiv.module.css"
function LoadJobsContent({hasFlexRow}) {
    const [loadedContent, setLoadedContent] = React.useState([]);
    const [counterContent, setCounterContent] = React.useState(0);
    const fetchurl = feapiurl + "getjobdata.php";

    React.useEffect(() => {
        const getData = async () => {
            try {
                const response = await axios.post(fetchurl);
                const result = response.data;
                setLoadedContent(result);
                setCounterContent(result.length);
            } catch (error) {
                console.error('Fehler', error);
            }
        };

        getData();
    }, []);
    const handleLinkClick = (route) => {
        window.scrollTo({behavior:'smooth',top:0});


    };
    return (
        <MasterDivs hasFlexRow={hasFlexRow}>
            {counterContent > 0 ? (
                <>
                    <h2 className={mdstyles.joblistheadline}>Unsere offenen Stellen</h2>
                    <ul className={mdstyles.joblist}>
                    {loadedContent.map((cd) => (
                        <li key={cd.anz_id}>
                            <Link to={"/"+cd.anz_routing} onClick={()=>handleLinkClick("/"+cd.anz_routing)}>
                            { cd.anz_title}
                            </Link>
                        </li>
                    ))}
                    </ul>
                </>
            ) : (
                ""
            )}
        </MasterDivs>
    );
}

export default LoadJobsContent;
